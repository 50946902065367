import axios from "axios";
import Constants from "../../core/constants/Constants";
import AuthService from "../AuthService/AuthService";
import { getCookie } from "../../utils/utils";
import Swal from "sweetalert2";
// import { router } from '../../routers/router'

/*
Apps Service
*/
export default class AppService {
  APPS_ENDPOINT = "/api/sgm-apps/";
  EVENTS_ENDPOINT = "/api/events/";

  async allApps() {
    let hasError = false;
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.APPS_ENDPOINT + "all/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      return res.data;
    }
  }

  async userApps() {
    let hasError = false;
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.APPS_ENDPOINT, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      return res.data;
    }
  }

  async createApp(app) {
    let hasError = false;
    await new AuthService().refreshToken();
    let formData = new FormData();
    for (let i in app) {
      if (Array.isArray(app[i])) {
        app[i].forEach((item) => {
          formData.append(i, item);
        });
      } else {
        formData.append(i, app[i]);
      }
    }
    await axios
      .post(Constants.BASE_URL + this.APPS_ENDPOINT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Preencha todos os campos",
        });
        return err.response.request;
      });

    if (hasError) return;
    window.location.reload(false);
  }

  async getAppId(id) {
    let hasError = false;
    await new AuthService().refreshToken();
    let res = await axios
      .get(Constants.BASE_URL + this.APPS_ENDPOINT + `${id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    let resOk =
      "status_code" in res.data
        ? (resOk = res.data["status_code"] === 200)
        : true;
    if (res && res.status == 200 && resOk) {
      return res.data;
    }
  }

  async getUpdateApp(app) {
    let hasError = false;
    await new AuthService().refreshToken();
    delete app.logo_thumb;
    if (!(app.logo instanceof File)) {
      delete app.logo;
    }
    let formData = new FormData();
    for (let i in app) {
      if (app[i] instanceof File || app[i].length > 0) {
        if (Array.isArray(app[i])) {
          app[i].forEach((item) => {
            formData.append(i, item);
          });
        } else {
          formData.append(i, app[i]);
        }
      }
    }

    let res = await axios
      .patch(Constants.BASE_URL + this.APPS_ENDPOINT + `${app.id}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        hasError = true;
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    if (hasError) return;

    if (res && res.status == 200) {
      Swal.fire({
        icon: "success",
        title: "Sucesso",
        text: "Edição realizada!",
      });
    }
  }

  async ordenaApp(evento) {
    await new AuthService().refreshToken();
    await axios
      .post(Constants.BASE_URL + this.EVENTS_ENDPOINT, evento, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });

    window.location.reload(false);
  }

  async deleteApp(id) {
    await new AuthService().refreshToken();
    await axios
      .delete(Constants.BASE_URL + this.APPS_ENDPOINT + id + "/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getCookie("access")}`,
        },
      })
      .catch((err) => {
        console.log(err.response);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: JSON.parse(err.request.response).detail,
        });
        return err.response.request;
      });
  }
}
