<template>
  <div>
    <div class="row mx-1 mt-3 mb-4" :key="$route.path">
      <div
        class="col-6 mt-3"
        v-for="bi in lista_bi"
        :key="bi"
        style="
          border-radius: 10px;
          box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%),
            0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
        "
      >
        <iframe
          title="Resumo_chamados_Paf_Uti - GERAL"
          width="100%"
          height="300"
          :src="bi.link"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </div>
    <div v-if="rota == 'organograma'">
      <img src="../../assets/org_2023.png" width="100%" height="100%" />
    </div>
    <div style="height: 70vh" v-if="rota == 'campanha'">
      <v-toolbar>
        <v-toolbar-title>{{ campanhaName }}</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="item">
          <img :src="url[index]" width="100%" height="100%" />
        </v-tab-item>
      </v-tabs-items>
    </div>

    <div style="height: 70vh" v-if="rota == 'indicadores'">
      <v-toolbar>
        <v-toolbar-title>{{ campanhaName }}</v-toolbar-title>

        <template v-slot:extension>
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="yellow"></v-tabs-slider>

            <v-tab v-for="item in items" :key="item">
              {{ item }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(item, index) in items" :key="item">
          <iframe
            :src="url[index]"
            width="100%"
            height="100%"
            type="application/pdf"
          ></iframe>
        </v-tab-item>
      </v-tabs-items>
    </div>

    <HomeBody :apps="apps" :appsLimit="appsLimit" v-if="apps" />

    <b-modal id="modal-1" size="xl" hide-footer hide-header>
      <div class="d-flex" style="justify-content: center; height: 80vh">
        <iframe
          title="100419-SLA_8h - Faixas SLA [ANUAL]"
          width="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiZDhhYmYyMzctOGQ0MC00MDc4LWIxMTQtYjI0NzgyY2QwMjAxIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </b-modal>
    <b-modal id="modal-2" size="xl" hide-footer hide-header>
      <div class="d-flex" style="justify-content: center; height: 80vh">
        <iframe
          title="Contratos_Publicos_Vencidos - Publicos_Vencidos"
          width="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiZWUzMDBkZDEtZThjZS00NmNiLTgzNjUtY2E4MmJmNGM3ZjdhIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </b-modal>
    <b-modal id="modal-3" size="xl" hide-footer hide-header>
      <div class="d-flex" style="justify-content: center; height: 80vh">
        <iframe
          title="Reinstalações_Software - MENU"
          width="100%"
          src="https://app.powerbi.com/view?r=eyJrIjoiMWFkZWNkNjctNGRlMS00YjY4LWEzY2YtOGNkZGJmZjFhYmVlIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9"
          frameborder="0"
          allowFullScreen="true"
        ></iframe>
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">
h4 {
  color: #05004e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  padding: 0px 20px;
}
h3 {
  color: #05004e;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
}
.title {
  color: #c10015;
  font-weight: bold;
  word-wrap: break-word;
}
.v-list-item__title:hover {
  color: #c10015;
}
.v-list-item__title {
  cursor: pointer;
}
.v-card__title {
  color: #151d48;
}
.card-1 {
  background: #ffe2e5;
  border-radius: 10px;
}
.card-2 {
  background: #fff4de;
  border-radius: 10px;
}
.card-3 {
  background: #e0f1fd;
  border-radius: 10px;
}
.card-4 {
  background: #dcfce7;
  border-radius: 10px;
}
.card-5 {
  background: #f3e8ff;
  border-radius: 10px;
}
.card-6 {
  background: #ffd5f8;
  border-radius: 10px;
}
.v-card__title {
  font-size: 18px;
}
</style>

<script>
// import HomeHead from './components/Homehead.vue'
import HomeBody from "./components/Homebody.vue";
import AreaService from "../../services/AreaService/AreaService";
import AppService from "../../services/AppService/AppService";
import IndicadorService from "../../services/IndicadorService/IndicadorService";
import CampaignsService from "../../services/CampaignsService/CampaignsService";
import Constants from "../../core/constants/Constants";

export default {
  components: { HomeBody },
  name: "HomePage",
  data() {
    return {
      apps: [],
      appService: null,
      appsLimit: [],
      slide: 0,
      sliding: null,
      biUrl: "",
      lista_bi: [],
      params: "",
      idRota: null,
      rota: null,
      campanhaName: null,
      url: [],
      items: [],
      tab: null,
      ano: [],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },
  mounted() {
    this.appService = new AppService();
    this.getAllApps();
  },

  created() {
    this.getBI();
  },

  watch: {
    $route(newValue, oldValue) {
      console.log(newValue, oldValue);
      this.getBI();
    },
  },
  methods: {
    async getAllApps() {
      this.apps = await this.appService.userApps();
      this.appsLimit = this.apps.slice(0, 15);
    },
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    abrirPlat(url) {
      window.open(url, "_blank");
    },
    async getBI() {
      this.items = [];
      this.url = [];
      if (!this.$route.params.id) {
        this.lista_bi.push({
          link: "https://app.powerbi.com/view?r=eyJrIjoiMTFkMTVhNWUtM2MyOC00YmM0LTgwMmItMzg3N2RkMDQzYTNjIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9",
        });
        this.lista_bi.push({
          link: "https://app.powerbi.com/view?r=eyJrIjoiOGVjM2IzMDgtMTIzOC00NjZiLWFlY2YtYzA2NDk4MTE0Y2FlIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9",
        });
        this.lista_bi.push({
          link: "https://app.powerbi.com/view?r=eyJrIjoiZWUzMDBkZDEtZThjZS00NmNiLTgzNjUtY2E4MmJmNGM3ZjdhIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9",
        });
        this.lista_bi.push({
          link: "https://app.powerbi.com/view?r=eyJrIjoiMjQ0MjEzMDgtNzdkNC00NWI5LWEzNTItZGE5ZjkwNzVkODdiIiwidCI6IjcwNjYxNzhhLWIzMzctNGUyZi1hYWNjLTA5NmZmMWRhMGM4MSJ9",
        });
      } else {
        this.lista_bi = [];
        this.rota = this.$route.path.split("/");
        this.rota = this.rota[1];
        this.idRota = this.$route.params.id;

        if (this.rota == "area") {
          const bis = await new AreaService().allBis();
          bis.forEach((element) => {
            element.departments.forEach((area) => {
              if (area == this.idRota) {
                this.lista_bi.push(element);
              }
            });
          });
        } else if (this.rota == "campanha") {
          let result = await new CampaignsService().allCampaigns();
          let campanhas = [];
          Object.keys(result).forEach((key) => {
            campanhas.push({ key, value: result[key] });
          });
          delete campanhas[0];
          let campanha = campanhas[parseInt(this.idRota)].value;
          this.campanhaName = campanhas[parseInt(this.idRota)].key;
          campanha.forEach((item) => {
            this.items.push(this.meses[item.month - 1]);
            this.url.push(Constants.BASE_URL + item.file);
          });
        } else if (this.rota == "organograma") {
          this.url.push("assets/org_2023.png");
        } else {
          const indicators = await new IndicadorService().allIndicators();
          indicators.forEach((element) => {
            if (element.id == this.idRota) {
              this.campanhaName = element.title;
              this.items.push(element.year);
              this.url.push(element.file);
            }
          });
        }
      }
    },
  },
};
</script>
