<template>
  <div class="mx-3" style="width: 100%">
    <v-footer
      fixed
      :padless="true"
      style="
        background-color: white;
        box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.15);
      "
      class="d-flex mt-1 px-auto"
    >
      <div class="col px-0" v-for="(app, i) in appsLimit" :key="i">
        <a
          class="d-flex justify-content-center"
          style="cursor: pointer"
          @click="abrirLink(app)"
        >
          <div class="">
            <div class="d-flex justify-content-center">
              <b-button
                v-b-tooltip.hover
                :title="app.name"
                style="background-color: white; border: none"
              >
                <img v-bind:src="app.logo_thumb" width="35px" height="35px" />
                <p
                  class="mb-0"
                  style="
                    color: black;
                    white-space: nowrap;
                    width: 7ch;
                    overflow: hidden;
                    text-overflow: clip;
                  "
                >
                  {{ app.name }}
                </p>
              </b-button>
            </div>
          </div>
        </a>
      </div>
      <div class="col-1">
        <a
          class="d-flex justify-content-center"
          style="cursor: pointer"
          @click="abrirLink(app)"
        >
          <div class="">
            <div class="d-flex justify-content-center">
              <v-dialog v-model="dialog" width="1000px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    style="background-color: white; box-shadow: 0px white"
                  >
                    <v-icon style="color: black; font-size: 40px">
                      apps
                    </v-icon>
                  </v-btn>
                </template>
                <v-card class="p-3">
                  <v-card-title class="headline"
                    >Veja seus aplicativos favoritos</v-card-title
                  >
                  <div class="row justify-content-center">
                    <div class="col-2 mt-2" v-for="(app, i) in apps" :key="i">
                      <a
                        class="d-flex justify-content-center"
                        style="cursor: pointer"
                        @click="abrirLink(app)"
                      >
                        <div class="">
                          <div class="d-flex justify-content-center">
                            <img
                              v-bind:src="app.logo_thumb"
                              width="45px"
                              height="45px"
                            />
                          </div>
                          <div
                            style="color: black"
                            class="mt-1 d-flex justify-content-center"
                          >
                            {{ app.name }}
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">
                      Fechar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </div>
        </a>
      </div>
    </v-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~/public/assets/styles/scss/pages/home.scss";
</style>

<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  padding-left: 0px;
  padding-right: 0px;
}
.v-btn__content {
  width: 100%;
}

.v-btn--is-elevated {
  box-shadow: 0px 0px 0px 0px;
}
</style>
<script>
import AppService from "@/services/AppService/AppService";
import UserService from "../../../services/UserService/UserService";
import { getCookie } from "../../../utils/utils";

export default {
  name: "HomeBody",
  props: ["apps", "appsLimit"],
  data() {
    return {
      dialog: false,
      appService: null,
    };
  },
  mounted() {
    this.appService = new AppService();
  },

  methods: {
    async abrirLink(app) {
      let idUser = await new UserService().me();
      this.appService.ordenaApp({
        app: app.id,
        user: idUser.id,
        event_type: 0,
      });
      let url = app.url;
      if (app.supports_jwt) {
        url += `?signature=${getCookie("access")}`;
      }
      window.open(url, "_blank");
    },
  },
};
</script>
